import { combineReducers } from 'redux'
import divRectReducer from './divRectReducer'
import animationReducer from './animationReducer'
import alignReducer from './alignReducer'

export default combineReducers({
	animationData: divRectReducer,
	animationState: animationReducer,
	alignState: alignReducer,
})
