// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/milan/Desktop/learn/example/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-js": () => import("/home/milan/Desktop/learn/example/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/home/milan/Desktop/learn/example/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/home/milan/Desktop/learn/example/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-index-js": () => import("/home/milan/Desktop/learn/example/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/home/milan/Desktop/learn/example/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-say-hello-js": () => import("/home/milan/Desktop/learn/example/src/pages/say-hello.js" /* webpackChunkName: "component---src-pages-say-hello-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/milan/Desktop/learn/example/.cache/data.json")

