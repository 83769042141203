import { DIV_INDEX } from '../actions/types'

const initialState = {
	align: '',
}

export default function(state = initialState, action) {
	switch (action.type) {
		case DIV_INDEX:
			return {
				...state,
				align: action.payload,
			}
		default:
			return state
	}
}
