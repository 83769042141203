import { DIV_RECT, DIV_REVERSE_RECT, IMAGE_RECT } from '../actions/types'

const initialState = {
	divRect: {
		bottom: '',
		height: '',
		left: '',
		right: '',
		top: '',
		width: '',
		x: '',
		y: '',
	},
	divReverseRect: {
		bottom: '',
		height: '',
		left: '',
		right: '',
		top: '',
		width: '',
		x: '',
		y: '',
	},
	imageRect: {
		bottom: '',
		height: '',
		left: '',
		right: '',
		top: '',
		width: '',
		x: '',
		y: '',
	},
}

export default function(state = initialState, action) {
	switch (action.type) {
		case DIV_RECT:
			return {
				...state,
				divRect: {
					bottom: action.payload.bottom,
					height: action.payload.height,
					left: action.payload.left,
					right: action.payload.right,
					top: action.payload.top,
					width: action.payload.width,
					x: action.payload.x,
					y: action.payload.y,
				},
			}
		case DIV_REVERSE_RECT:
			return {
				...state,
				divReverseRect: {
					bottom: action.payload.bottom,
					height: action.payload.height,
					left: action.payload.left,
					right: action.payload.right,
					top: action.payload.top,
					width: action.payload.width,
					x: action.payload.x,
					y: action.payload.y,
				},
			}
		case IMAGE_RECT:
			return {
				...state,
				imageRect: {
					bottom: action.payload.bottom,
					height: action.payload.height,
					left: action.payload.left,
					right: action.payload.right,
					top: action.payload.top,
					width: action.payload.width,
					x: action.payload.x,
					y: action.payload.y,
				},
			}
		default:
			return state
	}
}
