import { IS_ANIMATION, ANIMATION_STATUS } from '../actions/types'

const initialState = {
	isAnimating: false,
	play: false,
	reverse: false,
}

export default function(state = initialState, action) {
	switch (action.type) {
		case IS_ANIMATION:
			return {
				...state,
				isAnimating: action.payload,
			}
		case ANIMATION_STATUS:
			return {
				...state,
				play: action.payload.play,
				reverse: action.payload.reverse,
			}
		default:
			return state
	}
}
