module.exports = [{
      plugin: require('/home/milan/Desktop/learn/example/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"style":{"overlay":{"position":"fixed","zIndex":99999,"top":0,"width":"100%","height":"100%","left":"none","right":"none","bottom":"none","backgroundColor":"none","background":"none"},"content":{"borderRadius":"none","backgroundColor":"none","background":"none","outline":"none","position":"relative","width":"100%","height":"100%","border":"none","padding":"none","top":"none","bottom":"none","right":"none","left":"none","overflow":"auto","WebkitOverflowScrolling":"touch"}},"contentLabel":"Modal","shouldFocusAfterRender":false,"shouldCloseOnEsc":true,"shouldReturnFocusAfterClose":false}},
    },{
      plugin: require('/home/milan/Desktop/learn/example/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/milan/Desktop/learn/example/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
